import { createActions, createReducer } from "reduxsauce";
import DateUtil from "../../utils/Date";
import { filterByClientAndWord, sortPositions } from "../../utils/Position";
import { isAutomobileBoardAutoVaccum } from "../../utils/Patrimony";

import { getNipleFraud, getHatchStatusTolerance } from "./../../utils/Position";
import { getDescriptionClient } from "./../../utils/Client";

import ClientSchemaReduced from "../../services/Client/SchemaReduced";


/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addLastPositionProblems: ["lastPosition"],
  delLastPositionProblems: ["id"],
  updateLastPositionProblems: ["id", "lastPosition"],
  searchLastPositionProblems: ["search"],
  selectLastPositionProblems: ["id"],
  setLastPositionProblems: ["lastPositions"],
  setLastPositionPatrimonyTypeProblems: ["niple"],
  setLastPositionPatrimonyNipleTypeProblems: ["nipleType"],
  setLastPositionSortProblems: ["lastPositionSortAsc", "lastPositionSortDate", "lastPositionSortDischarge", "lastPositionSortName"],
  setLastPositionShowProblems: ["positionShow"],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  positionsClients: [],
  positions: [],
  positionsSearch: [],
  positionsSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    events: [],
    word: "",
    city: "",
    niple: true,
    nipleType: "ALL" //ALL | ELE | MEC
  },
  positionsSearchQueryEnabled: true,
  positionSelected: false,
  positionsLoad: false,
  positionsNipleEnabled: true,
  positionsVehicleEnabled: true,
  positionsSortAsc: true,
  positionsSortDate: false,
  positionsSortDischarge: false,
  positionsSortName: true,
  positionShow: false
};

const format = (lastPosition) => {
  const copy = JSON.parse(JSON.stringify(lastPosition));
  if(typeof copy.patrimony === "undefined") {
    copy.patrimony = {
      automobile: {
        board: {
          autoVaccum: {
            license: copy.tracker.serial
          },
          board: copy.tracker.serial,
          color: "GRAY",
          country: "BR",
          description: "SC - CHAPECÓ",
          layout: "DEF",
          type: "CAR"
        },
        brand: "TPX",
        card: {
          number: copy.tracker.serial
        },
        color: "WHITE",
        model: "OCTSAT",
        type: "BOA"
      },
      object: {
        name: copy.tracker.serial
      },
      type: "AUT",
      vessel: {
        name: copy.tracker.serial,
        registrationNumber: copy.tracker.serial
      }
    };
  }
  return copy;
};

const getNipleFraudText = (fraud) => {
  let message = false;
  switch (fraud) {
    case "Title.Niple.Fraud.BackCover":
      message = "tampa traseira";
      break;
    case "Title.Niple.Fraud.Center":
      message = "fora de centro";
      break;
    case "Title.Niple.Fraud.ExternalPower":
      message = "alimentação externa";
      break;
    case "Title.Niple.Fraud.OpenHead":
      message = "cabeçote aberto";
      break;
    case "Title.Niple.Fraud.Rotation":
      message = "rotação";
      break;
    default:
      message = false;
      break;
  }
  return message;
};

const checkProblems = (lastPosition) => {
  const position = {...lastPosition};
  const vehicle = position.patrimony;
  const events = position._events;
  const trackersSerials = vehicle.trackers.map(tracker => ({
    delayedLocation: false,
    fraud: false,
    panic: false,
    lastPosition: false,
    serial: tracker.serial,
    type: tracker.type,
  }));
  let version = "Mecânico";
  const isNiple = isAutomobileBoardAutoVaccum(vehicle);
  for (const trackerSerial of trackersSerials) {
    if (trackerSerial.type === "TRA" || trackerSerial.type === "TRA_NIP") {
      trackerSerial.lastPosition = position.gps.date;
      const diffMain = DateUtil.diff({ dateA: Date.now(), dateB: position.gps.date, format: "days" });
      if (diffMain > 2) trackerSerial.delayedLocation = true;
      if (position.tracker.battery) trackerSerial.fraud = "falha na alimentação";
      if (position.tracker.panic)  trackerSerial.panic = true;
    }
    else {
      if (position.nipleNetwork) {
        if (position.nipleNetwork.niples) {
          const niple = position.nipleNetwork.niples.find(niple => niple.serial === trackerSerial.serial);
          if (niple) {
            trackerSerial.lastPosition = niple.gps.date;
            trackerSerial.fraud = getNipleFraudText(getNipleFraud(position, niple));
            const diffNiple = DateUtil.diff({ dateA: Date.now(), dateB: niple.gps.date, format: "days" });
            if (diffNiple > 2) trackerSerial.delayedLocation = true;
            if (niple.version === "3.0.0") version = "Piezo";
            else if (niple.sensors && ((niple.sensors.s1 > 0) || (niple.sensors.s2 > 0) || (niple.sensors.s3 > 0) || (niple.sensors.s4 > 0) || (niple.sensors.s5 > 0) || (niple.sensors.s6 > 0))) version = "Eletrôdo";
          }
        }
        if (position.nipleNetwork.hatchs) {
          for (const hatch of position.nipleNetwork.hatchs) {
            const trackerHatch = trackersSerials.find(trackersSerial => trackersSerial.serial === hatch.serial);
            if (trackerHatch) {
              trackerHatch.lastPosition = hatch.date;
              const diffHatch = DateUtil.diff({ dateA: Date.now(), dateB: hatch.date, format: "days" });
              if (diffHatch > 2) trackerHatch.delayedLocation = true;

              const newHatchMin = hatch.sensors.shortestDistance - getHatchStatusTolerance(hatch.sensors.shortestDistance);
              const newHatchMax = hatch.sensors.greaterDistance + getHatchStatusTolerance(hatch.sensors.greaterDistance);
              const newHatchDistance = hatch.sensors.distance;
              const newHatchExternalPower = !hatch.externalPower;
              const hatchFraud = [];
              if ((newHatchDistance < newHatchMin) || (newHatchDistance > newHatchMax)) hatchFraud.push("escotilha aberta");
              if (newHatchExternalPower === false) hatchFraud.push("falha na alimentação");
              trackerHatch.fraud = hatchFraud.length === 0 ? false : hatchFraud.join(", ");
            }
            else {
              const hatchItem = {
                delayedLocation: false,
                fraud: false,
                lastPosition: hatch.date,
                serial: hatch.serial,
                type: "HAT"
              };
              const diffHatch = DateUtil.diff({ dateA: Date.now(), dateB: hatch.date, format: "days" });
              if (diffHatch > 2) hatchItem.delayedLocation = true;
              const newHatchMin = hatch.sensors.shortestDistance - getHatchStatusTolerance(hatch.sensors.shortestDistance);
              const newHatchMax = hatch.sensors.greaterDistance + getHatchStatusTolerance(hatch.sensors.greaterDistance);
              const newHatchDistance = hatch.sensors.distance;
              const newHatchExternalPower = !hatch.externalPower;
              const hatchFraud = [];
              if ((newHatchDistance < newHatchMin) || (newHatchDistance > newHatchMax)) hatchFraud.push("escotilha aberta");
              if (newHatchExternalPower === false) hatchFraud.push("falha na alimentação");
              hatchItem.fraud = hatchFraud.length === 0 ? false : hatchFraud.join(", ");
              trackersSerials.push(hatchItem);
            }
          }
        }
      }
    }
  }

  const installAt = DateUtil.diff({ dateA: Date.now(), dateB: vehicle.installAt, format: "days" });
  const lastNipleDischarge = events ? (events.lastNipleDischarge ? events.lastNipleDischarge : false) : false;
  const lastNipleDischargeDiff = lastNipleDischarge ? DateUtil.diff({ dateA: Date.now(), dateB: lastNipleDischarge, format: "days" }) : (installAt <= 30 ? 0 : -1);

  const isDelayed = trackersSerials.find(trackersSerial => trackersSerial.delayedLocation !== false);
  const isFraud = trackersSerials.find(trackersSerial => trackersSerial.fraud !== false);
  const isPanic = trackersSerials.find(trackersSerial => trackersSerial.panic !== false);
  const isEmpty = trackersSerials.find(trackersSerial => trackersSerial.lastPosition === false);
  const isDischargeFalse = lastNipleDischargeDiff > 30 || lastNipleDischargeDiff === -1;

  if (isDelayed || isFraud || isPanic || isEmpty || (isDischargeFalse && isNiple)) {

    const problemsHatch = [];
    const problemsNiple = [];
    const problemsTracker = [];

    if (isNiple) {
      if (lastNipleDischargeDiff > 30) problemsNiple.push(`NÃO DESCARREGA A PELO MENOS ${lastNipleDischargeDiff} DIAS`);
      if (lastNipleDischargeDiff === -1) problemsNiple.push("NÃO DESCARREGA A PELO MENOS 1 ANO");
    }

    trackersSerials.filter(trackersSerial => trackersSerial.delayedLocation !== false).map(trackersSerial => {
      if (isNiple && trackersSerial.type === "NIP") problemsNiple.push("NIPLE ATRASADO");
      if (isNiple && trackersSerial.type === "HAT") problemsHatch.push("ESCOTILHA ATRASADA");
      if (trackersSerial.type === "TRA" || trackersSerial.type === "TRA_NIP") problemsTracker.push("RASTREADOR ATRASADO");
    });

    trackersSerials.filter(trackersSerial => trackersSerial.fraud !== false).map(trackersSerial => {
      if (isNiple && trackersSerial.type === "NIP") problemsNiple.push("BURLA NIPLE: " + String(trackersSerial.fraud).toUpperCase());
      if (isNiple && trackersSerial.type === "HAT") problemsHatch.push("BURLA ESCOTILHA: " + String(trackersSerial.fraud).toUpperCase());
      if (trackersSerial.type === "TRA" || trackersSerial.type === "TRA_NIP") problemsTracker.push("BURLA RASTREADOR: " + String(trackersSerial.fraud).toUpperCase());
    });

    trackersSerials.filter(trackersSerial => trackersSerial.panic !== false).map(trackersSerial => {
      if (trackersSerial.type === "TRA" || trackersSerial.type === "TRA_NIP") problemsTracker.push("BOTÃO DE PÂNICO ACIONADO");
    });

    trackersSerials.filter(trackersSerial => trackersSerial.lastPosition === false).map(trackersSerial => {
      if (isNiple && trackersSerial.type === "NIP") problemsNiple.push("NIPLE: SUMIU OU DIFERE DO CADASTRO");
      if (isNiple && trackersSerial.type === "HAT") problemsHatch.push("ESCOTILHA: SUMIU OU DIFERE DO CADASTRO");
      if (trackersSerial.type === "TRA" || trackersSerial.type === "TRA_NIP") problemsTracker.push("RASTREADOR: SUMIU OU DIFERE DO CADASTRO");
    });

    if (problemsHatch.length > 0 || problemsNiple.length > 0 || problemsTracker.length > 0) {
      position._reasonProblem = {
        problems: {
          problemsHatch,
          problemsNiple,
          problemsTracker
        },
        version
      };
      return position;
    }
  }
  return false;
};

const add = (state = INITIAL_STATE, action) => {
  const { lastPosition } = action;
  if(state.positionsLoad && lastPosition !== null) {
    const lastPositionCheck = checkProblems(format(lastPosition));

    const index = state.positions.findIndex(x => x.id === lastPosition.tracker.serial);
    if (index !== -1) {
      if (!lastPositionCheck) return del(state, { id: lastPosition.tracker.serial });
      return update(
        state,
        {
          id: lastPosition.tracker.serial,
          lastPosition: lastPositionCheck
        }
      );
    }

    if (lastPositionCheck) {
      const positions = state.positions;
      positions.push({ id: lastPositionCheck.tracker.serial, position: lastPositionCheck, selected: false });

      const positionsClients = state.positionsClients;
      const haveClient = lastPositionCheck.patrimony && lastPositionCheck.patrimony.client && lastPositionCheck.patrimony.client.id;
      if (haveClient) {
        const client = positionsClients.find(positionsClient => positionsClient.id === lastPositionCheck.patrimony.client.id);
        if (!client) {
          positionsClients.push({
            original: lastPositionCheck.patrimony.client,
            id: lastPositionCheck.patrimony.client.id,
            name: getDescriptionClient(lastPositionCheck.patrimony.client),
            haveNiple: isAutomobileBoardAutoVaccum(lastPositionCheck.patrimony),
            haveVehicle: !isAutomobileBoardAutoVaccum(lastPositionCheck.patrimony),
            count: 1
          });
        }
        else {
          if (!client.haveNiple) client.haveNiple = isAutomobileBoardAutoVaccum(lastPositionCheck.patrimony);
          if (!client.haveVehicle) client.haveVehicle = !isAutomobileBoardAutoVaccum(lastPositionCheck.patrimony);
          client.count++;
        }
      }

      const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkNiple.length === 0) {
        state.positionsNipleEnabled = false;
        state.positionsSearchQuery.niple = false;
      }
      const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkVehicle.length === 0) {
        state.positionsVehicleEnabled = false;
        state.positionsSearchQuery.niple = true;
      }

      const { positionsSearch } = search({
        ...state,
        positions
      }, { search: state.positionsSearchQuery });

      return {
        ...state,
        positionsClients: positionsClients.sort((a, b) => a.name.localeCompare(b.name)),
        positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName)
      };
    }
  }
  return state;
};

const del = (state = INITIAL_STATE, action) => {
  const { id } = action;
  if(state.positionsLoad && (id !== null)) {
    const index = state.positions.findIndex(x => x.id === id);
    if(index !== -1) {
      let positionSelected = false;
      const positions = [...state.positions];
      positions.splice(index, 1);

      const positionToDel = JSON.parse(JSON.stringify(state.positions[index]));
      const positionsClients = state.positionsClients;
      const haveClient = positionToDel.patrimony && positionToDel.patrimony.client && positionToDel.patrimony.client.id;
      if (haveClient) {
        const client = positionsClients.find(positionsClient => positionsClient.id === positionToDel.patrimony.client.id);
        if (client) client.count--;
      }

      const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkNiple.length === 0) {
        state.positionsNipleEnabled = false;
        state.positionsSearchQuery.niple = false;
      }
      const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkVehicle.length === 0) {
        state.positionsVehicleEnabled = false;
        state.positionsSearchQuery.niple = true;
      }

      const { positionsSearch } = search({
        ...state,
        positions,
        positionSelected
      }, { search: state.positionsSearchQuery });

      return {
        ...state,
        positionsClients,
        positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionSelected
      };
    }
  }
  return state;
};

const update = (state = INITIAL_STATE, action) => {
  const { id, lastPosition } = action;
  if(state.positionsLoad && (id !== null && lastPosition !== null)) {
    const index = state.positions.findIndex(x => x.id === id);
    if(index !== -1) {
      let positionSelected = state.positionSelected;
      const positions = state.positions.map(position => {
        if((position.id === id) && ((DateUtil.diff({ dateA: position.position.gps.date, dateB: lastPosition.gps.date }) < 0) || (DateUtil.diff({ dateA: position.position.gps.date, dateB: lastPosition.gps.date }) === 0 && DateUtil.diff({ dateA: position.position.createdAt, dateB: lastPosition.createdAt }) <= 0))) {
          if(position.selected) {
            positionSelected = { ...position, position: format(lastPosition) };
          }
          return { ...position, position: format(lastPosition) };
        }
        return { ...position };
      });

      const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkNiple.length === 0) {
        state.positionsNipleEnabled = false;
        state.positionsSearchQuery.niple = false;
      }
      const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkVehicle.length === 0) {
        state.positionsVehicleEnabled = false;
        state.positionsSearchQuery.niple = true;
      }

      const { positionsSearch } = search({
        ...state,
        positions,
        positionSelected
      }, { search: state.positionsSearchQuery });

      return {
        ...state,
        positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionSelected
      };
    }
  }
  return state;
};

const search = (state = INITIAL_STATE, action) => {
  const { search } = action;
  search.niple = state.positionsSearchQuery.niple;
  search.nipleType = state.positionsSearchQuery.nipleType;
  const { enabled: positionsSearchEnabled, results: positionsSearchResults } = filterByClientAndWord(state.positions, search, true);
  return {
    ...state,
    positionsSearch: positionsSearchResults,
    positionsSearchQuery: search,
    positionsSearchQueryEnabled: positionsSearchEnabled
  };
};

const select = (state = INITIAL_STATE, action) => {
  const { id } = action;
  let positionSelected = state.positionSelected;
  const positions = state.positions.map(position => {
    if(position.id === id) {
      positionSelected = false;
      if(!position.selected) {
        positionSelected = { ...position, selected: !position.selected };
      }
      return { ...position, selected: !position.selected };
    }
    return { ...position, selected: false };
  });
  const positionsSearch = state.positionsSearch.map(position => position.id === id ? { ...position, selected: !position.selected } : { ...position, selected: false });
  return {
    ...state,
    positions,
    positionsSearch,
    positionSelected
  };
};

const set = (state = INITIAL_STATE, action) => {
  const { lastPositions } = action;
  if(state.positionsLoad === false && lastPositions !== null) {
    const lastPositionsChecks = lastPositions.map(lastPosition => checkProblems(format(lastPosition))).filter(lastPositionCheck => lastPositionCheck !== false);

    const positions = lastPositionsChecks.map(lastPosition => {
      return { id: lastPosition.tracker.serial, position: lastPosition, selected: false };
    });

    const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
    if (checkNiple.length === 0) {
      state.positionsNipleEnabled = false;
      state.positionsSearchQuery.niple = false;
    }
    const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
    if (checkVehicle.length === 0) {
      state.positionsVehicleEnabled = false;
      state.positionsSearchQuery.niple = true;
    }

    const positionsClients = [];
    positions.map(positionFull => {
      const { position } = positionFull;
      const haveClient = position.patrimony && position.patrimony.client && position.patrimony.client.id;
      if (haveClient) {
        const client = positionsClients.find(positionsClient => positionsClient.id === position.patrimony.client.id);
        if (!client) {
          positionsClients.push({
            original: position.patrimony.client,
            id: position.patrimony.client.id,
            name: getDescriptionClient(position.patrimony.client),
            haveNiple: isAutomobileBoardAutoVaccum(position.patrimony),
            haveVehicle: !isAutomobileBoardAutoVaccum(position.patrimony),
            count: 1
          });
          return positionFull;
        }
        if (!client.haveNiple) client.haveNiple = isAutomobileBoardAutoVaccum(position.patrimony);
        if (!client.haveVehicle) client.haveVehicle = !isAutomobileBoardAutoVaccum(position.patrimony);
        client.count++;
      }
      return positionFull;
    });

    const { positionsSearch } = search({
      ...state,
      positions
    }, { search: state.positionsSearchQuery });

    return {
      ...state,
      positionsClients: positionsClients.sort((a, b) => a.name.localeCompare(b.name)),
      positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
      positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
      positionsLoad: true
    };
  }
  return state;
};

const setLastPositionPatrimonyTypeProblems = (state = INITIAL_STATE, action) => {
  const { niple } = action;
  const positions = state.positions;
  const { positionsSearch } = search({
    ...state,
    positions,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      niple
    }
  }, { search: state.positionsSearchQuery });

  return {
    ...state,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      niple
    },
    positionsSearch
  };
};

const setLastPositionPatrimonyNipleTypeProblems = (state = INITIAL_STATE, action) => {
  const { nipleType } = action;
  const positions = state.positions;
  const { positionsSearch } = search({
    ...state,
    positions,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      nipleType
    }
  }, { search: state.positionsSearchQuery });

  return {
    ...state,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      nipleType
    },
    positionsSearch
  };
};

const setLastPositionSortProblems = (state = INITIAL_STATE, action) => {
  const { lastPositionSortAsc, lastPositionSortDate, lastPositionSortDischarge, lastPositionSortName } = action;
  return {
    ...state,
    positionsSortAsc: lastPositionSortAsc,
    positionsSortDate: lastPositionSortDate,
    positionsSortDischarge: lastPositionSortDischarge,
    positionsSortName: lastPositionSortName,
    positions: sortPositions(state.positions, lastPositionSortAsc, lastPositionSortDate, lastPositionSortDischarge, lastPositionSortName),
    positionsSearch: sortPositions(state.positionsSearch, lastPositionSortAsc, lastPositionSortDate, lastPositionSortDischarge, lastPositionSortName)
  };
};

const setShow = (state = INITIAL_STATE, action) => {
  const { positionShow } = action;
  if (state.positionsLoad && positionShow !== null) {
    return {
      ...state,
      positionShow
    };
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_LAST_POSITION_PROBLEMS]: add,
  [Types.DEL_LAST_POSITION_PROBLEMS]: del,
  [Types.UPDATE_LAST_POSITION_PROBLEMS]: update,
  [Types.SEARCH_LAST_POSITION_PROBLEMS]: search,
  [Types.SELECT_LAST_POSITION_PROBLEMS]: select,
  [Types.SET_LAST_POSITION_PROBLEMS]: set,
  [Types.SET_LAST_POSITION_PATRIMONY_TYPE_PROBLEMS]: setLastPositionPatrimonyTypeProblems,
  [Types.SET_LAST_POSITION_PATRIMONY_NIPLE_TYPE_PROBLEMS]: setLastPositionPatrimonyNipleTypeProblems,
  [Types.SET_LAST_POSITION_SORT_PROBLEMS]: setLastPositionSortProblems,
  [Types.SET_LAST_POSITION_SHOW_PROBLEMS]: setShow
});
