import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "../../utils/Date";
import { getDescriptionClient } from "../../utils/Client";
import { getDescriptionPatrimony } from "../../utils/Patrimony";
import { getNiple, getNipleBasic } from "../../utils/Position";
import { isScopes } from "../../services/Auth";

const getNipleVersion = (position) => {
  if (position) {
    if (position.nipleNetwork.niples) {
      let version = "Mecânico";
      for (const niple of position.nipleNetwork.niples) {
        if (niple.version === "3.0.0") version = "Piezo";
        else if (niple.sensors && ((niple.sensors.s1 > 0) || (niple.sensors.s2 > 0) || (niple.sensors.s3 > 0) || (niple.sensors.s4 > 0) || (niple.sensors.s5 > 0) || (niple.sensors.s6 > 0))) version = "Eletrôdo";
      }
      return version;
    }
  }
  return "";
}

const LastPositionListItemProblems = ({
  options: { onClick, position, selected },
  props: { currentUser }
}) => {
  const { t } = useTranslation();

  let hatchs = [];
  let niples = [];
  if(isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
    const nipleBasic = getNipleBasic(position, false);
    niples = nipleBasic.niples;
  }
  else if(isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
    const nipleMaster = getNiple(position, false);
    hatchs = nipleMaster.hatchs;
    niples = nipleMaster.niples;
  }

  return (
    <li className={`last-position-list-item ${selected ? "active" : ""}`} onClick={onClick}>
      {
        (
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
          && typeof position._events !== "undefined"
          && (
            position._events.lastNipleCharge ||
            position._events.lastNipleChargeTest ||
            position._events.lastNipleDischarge ||
            position._events.lastNipleDischargeTest
          )
          && (
            <div className="positions-item-events-description">
              <ul>
                {
                  (
                    position._events.lastNipleCharge
                    && (
                      <li>
                        <div className="icon">C</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleCharge), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleChargeTest
                    && (
                      <li>
                        <div className="icon" style={{ color: "#999999" }}>C</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleChargeTest), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleDischarge
                    && (
                      <li>
                        <div className="icon">D</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleDischarge), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleDischargeTest
                    && (
                      <li>
                        <div className="icon" style={{ color: "#999999" }}>D</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleDischargeTest), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
              </ul>
            </div>
          )
        )
      }
      <ul>
        <li>
          <div>{t("Title.Client")}:</div>
          <div><span><strong>{getDescriptionClient(position.patrimony.client)}</strong></span></div>
        </li>
        <li>
          <div>{t("Patrimony")}:</div>
          <div><span><strong>{getDescriptionPatrimony(position.patrimony)}</strong></span></div>
        </li>
        <li>
          <div>Tipo:</div>
          <div><span><strong>{getNipleVersion(position)}</strong></span></div>
        </li>
        <li>
          <div>{t("Address")}:</div>
          <div><span>{position.gps.address}</span></div>
        </li>
      </ul>
      <div className="last-pos-problem">
        {
          ((position._reasonProblem.problems.problemsNiple && position._reasonProblem.problems.problemsNiple.length > 0) && (
            <>
              <div className="address">Problemas de Niple:</div>
              {
                position._reasonProblem.problems.problemsNiple.map((problem, index) => (
                  <div className="address" style={{ paddingLeft: "10px", fontWeight: "normal" }} key={`lp-problem-niple-${position.id}-problem-${index}`}>- {problem}</div>
                ))
              }
            </>
          ))
        }
        {
          ((position._reasonProblem.problems.problemsHatch && position._reasonProblem.problems.problemsHatch.length > 0) && (
            <>
              <div className="address">Problemas de Escotilha:</div>
              {
                position._reasonProblem.problems.problemsHatch.map((problem, index) => (
                  <div className="address" style={{ paddingLeft: "10px", fontWeight: "normal" }} key={`lp-problem-hatch-${position.id}-problem-${index}`}>- {problem}</div>
                ))
              }
            </>
          ))
        }
        {
          ((position._reasonProblem.problems.problemsTracker && position._reasonProblem.problems.problemsTracker.length > 0) && (
            <>
              <div className="address">Problemas de Rastreador:</div>
              {
                position._reasonProblem.problems.problemsTracker.map((problem, index) => (
                  <div className="address" style={{ paddingLeft: "10px", fontWeight: "normal" }} key={`lp-problem-tracker-${position.id}-problem-${index}`}>- {problem}</div>
                ))
              }
            </>
          ))
        }
      </div>
      <div className={`positions-item-ignition ${position.tracker.ignition ? "ignition-on" : "ignition-off"}`}><i className="fas fa-key"></i></div>
    </li>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionListItemProblems);
