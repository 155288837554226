import React, { useState, useEffect } from "react";
import {
  Form,
  OverlayTrigger,
  Row,
  Col,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import debounce from "lodash.debounce";
import Select from "react-select";
import "./index.css";

import { Creators as LastPositionProblemsActions } from "../../store/ducks/lastPositionsProblems";

import { mergeObject } from "../../utils/Object";
import { isScopes } from "../../services/Auth";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";

const LastPositionSearchProblems = ({
  props: { currentUser, databaseTest, lastPositionPatrimonyTypeProblems, lastPositionsClientsProblems, lastPositionSelectedProblems, lastPositionsSortAscProblems, lastPositionsSortDateProblems, lastPositionsSortDischargeProblems, lastPositionsSortNameProblems },
  funcs: { searchLastPositionProblems, setLastPositionSortProblems }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const [positionsSearch, setPositionsSearch] = useState({
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    events: [],
    word: "",
    city: ""
  });

  const [clientSelectedOption, setClientSelectedOption] = useState(positionsSearch.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), positionsSearch.client),
    label: positionsSearch.client.type === "LEG" ? positionsSearch.client.legal.socialReason : positionsSearch.client.personal.name,
    value: positionsSearch.client.id
  });

  useEffect(() => {
    searchLastPositionProblems(positionsSearch);
  }, [positionsSearch, searchLastPositionProblems]);

  useEffect(() => {
    resetClient();
    resetSearch();
  }, [databaseTest]);

  // const clientLoadOptions = (lastPositionsClientsProblems) => {
  //   const clients = lastPositionsClientsProblems.filter(lastPositionsClientsProblem => String(lastPositionsClientsProblem.name).toLocaleLowerCase().indexOf(String(e).toLocaleLowerCase()) !== -1);
  //   return filterClient(clients);
  // };

  // const clientLoadOptionsDebounce = debounce(async (e, c) => {
  //   const clients = lastPositionsClientsProblems.filter(lastPositionsClientsProblem => String(lastPositionsClientsProblem.name).toLocaleLowerCase().indexOf(String(e).toLocaleLowerCase()) !== -1);
  //   // const clients = await clientService.findAutocomplete({ data: {
  //   //   search: e
  //   // }, token: currentUser.token });
  //   c(filterClient(clients));
  // }, 1000);

  const filterClient = (clients) => {
    return clients.map(c => {
      const e = c.original;
      return {
        dataAux: e,
        label: e.type === "LEG" ? e.legal.socialReason : e.personal.name,
        value: e.id
      };
    });
  };

  const resetClient = () => {
    setClientSelectedOption(null);
    setPositionsSearch(prevState => ({
      ...prevState,
      client: JSON.parse(JSON.stringify(ClientSchemaReduced))
    }));
  };

  const resetSearch = () => {
    setPositionsSearch({
      client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
      events: [],
      word: "",
      city: "",
    });
  };

  const hasEvent = (type) => positionsSearch.events.findIndex(x => x === type) !== -1;

  const handleEvent = (type) => {
    const positionsSearchAux = {...positionsSearch};
    let index = positionsSearchAux.events.findIndex(x => x === type);
    if (index === -1) {
      positionsSearchAux.events.push(type);
    }
    else {
      positionsSearchAux.events.splice(index, 1);
    }
    setPositionsSearch(prevState => ({
      ...prevState,
      events: positionsSearchAux.events
    }));
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      {
        isScopes({ currentUser, scopes: [
          "is:master",
          "read:patrimonies:all"
        ], every: false }) ? (
            <Form.Group controlId="positions-item-form-search-client">
              <Select
                className="menu-outer-bottom"
                classNamePrefix="select"
                loadingMessage={() => t("React.Select.Wait")}
                noOptionsMessage={() => t("React.Select.NoOptions")}
                isClearable
                onChange={e => {
                  if(e === null) {
                    resetClient();
                  }
                  else {
                    const { dataAux, label, value } = e;
                    setClientSelectedOption({ dataAux, label, value });
                    setPositionsSearch(prevState => ({
                      ...prevState,
                      client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                    }));
                  }
                }}
                options={filterClient(lastPositionsClientsProblems.filter(lastPositionsClientsProblem => {
                  if (lastPositionPatrimonyTypeProblems) return lastPositionsClientsProblem.haveNiple;
                  return lastPositionsClientsProblem.haveVehicle;
                }))}
                placeholder={t("React.Select.SelectClient")}
                value={clientSelectedOption}
              />
            </Form.Group>
          ) : null
      }
      <Row style={{ marginLeft: 0, marginRight: 0, marginBottom: "5px" }}>
        <Col style={{ paddingLeft: 0, paddingRight: "5px" }}>
          <Form.Group controlId="positions-item-form-search" style={{ margin: 0 }}>
            <Form.Control
              type="text"
              placeholder={"Buscar pela placa"}
              autoComplete={"off"}
              onChange={e => setPositionsSearch(prevState => ({
                ...prevState,
                word: e.target.value
              }))}
              value={positionsSearch.word}
              disabled={lastPositionSelectedProblems ? true : false}
            />
          </Form.Group>
        </Col>
        <Col style={{ paddingRight: 0, paddingLeft: "5px" }}>
          <Form.Group controlId="positions-item-form-search-city" style={{ margin: 0 }}>
            <Form.Control
              type="text"
              placeholder={"Buscar pela cidade"}
              autoComplete={"off"}
              onChange={e => setPositionsSearch(prevState => ({
                ...prevState,
                city: e.target.value
              }))}
              value={positionsSearch.city}
              disabled={lastPositionSelectedProblems ? true : false}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <ul className="last-position-patrimony-type">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.NameAsc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={lastPositionsSortAscProblems && lastPositionsSortNameProblems ? "active" : ""} onClick={() => setLastPositionSortProblems(true, false, false, true)}>
              <i className="fas fa-sort-alpha-down" />
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.NameDesc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={!lastPositionsSortAscProblems && lastPositionsSortNameProblems ? "active" : ""} onClick={() => setLastPositionSortProblems(false, false, false, true)}>
              <i className="fas fa-sort-alpha-down-alt" />
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.TimeAsc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={lastPositionsSortAscProblems && lastPositionsSortDateProblems ? "active" : ""} onClick={() => setLastPositionSortProblems(true, true, false, false)}>
              <i className="fas fa-hourglass-end" />
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.TimeDesc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={!lastPositionsSortAscProblems && lastPositionsSortDateProblems ? "active" : ""} onClick={() => setLastPositionSortProblems(false, true, false, false)}>
              <i className="fas fa-hourglass-start" />
            </li>
          </OverlayTrigger>
          {
            isScopes({ currentUser, scopes: [
              "is:master",
              "read:patrimonies:all"
            ], every: false }) ? (
              <>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{t("Title.Sort.DischargeAsc")}</Tooltip>}
                  trigger={isMobile ? null : ["hover", "focus"]}
                >
                  <li className={lastPositionsSortAscProblems && lastPositionsSortDischargeProblems ? "active" : ""} onClick={() => setLastPositionSortProblems(true, false, true, false)}>
                    <i className="fas fa-long-arrow-alt-up"></i>
                    D
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{t("Title.Sort.DischargeDesc")}</Tooltip>}
                  trigger={isMobile ? null : ["hover", "focus"]}
                >
                  <li className={!lastPositionsSortAscProblems && lastPositionsSortDischargeProblems ? "active" : ""} onClick={() => setLastPositionSortProblems(false, false, true, false)}>
                    <i className="fas fa-long-arrow-alt-down"></i>
                    D
                  </li>
                </OverlayTrigger>
              </>
            ) : null
          }
        </ul>
      </Form.Group>
      {
        isScopes({ currentUser, scopes: [
          "is:master",
          "read:patrimonies:all"
        ], every: false }) ? (
          <Form.Group>
            <ul className="last-position-patrimony-event-type">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Filtrar niple</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("problemsNiple") ? "active" : ""} onClick={() => handleEvent("problemsNiple")}>
                  <div className="icon">N</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Filtrar escotilha</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("problemsHatch") ? "active" : ""} onClick={() => handleEvent("problemsHatch")}>
                  <div className="icon">E</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Filtrar rastreador</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("problemsTracker") ? "active" : ""} onClick={() => handleEvent("problemsTracker")}>
                  <div className="icon">R</div>
                </li>
              </OverlayTrigger>
            </ul>
          </Form.Group>
        ) : null
      }
    </form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionPatrimonyTypeProblems: state.lastPositionsProblems.positionsSearchQuery.niple,
    lastPositionsClientsProblems: state.lastPositionsProblems.positionsClients,
    lastPositionSelectedProblems: state.lastPositionsProblems.positionSelected,
    lastPositionsSortAscProblems: state.lastPositionsProblems.positionsSortAsc,
    lastPositionsSortDateProblems: state.lastPositionsProblems.positionsSortDate,
    lastPositionsSortDischargeProblems: state.lastPositionsProblems.positionsSortDischarge,
    lastPositionsSortNameProblems: state.lastPositionsProblems.positionsSortName
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastPositionProblemsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionSearchProblems);
