export function stringToSearch(string) {
  return string.toLowerCase().replace(/[ÀÁÂÃÄÅ]/g,"A")
    .replace(/[aáàãâä]/g,"a")
    .replace(/[EÉÈÊË]/g,"E")
    .replace(/[eéèêë]/g,"e")
    .replace(/[IÍÌÎÏ]/g,"I")
    .replace(/[iíìîï]/g,"i")
    .replace(/[OÓÒÕÔÖ]/g,"O")
    .replace(/[oóòõôö]/g,"o")
    .replace(/[UÚÙÛÜ]/g,"U")
    .replace(/[uúùûü]/g,"u")
    .replace(/[ç]/g,"c")
    .replace(/[Ç]/g,"C");
}

export function getFixed(num, fixed) {
  const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export function getKm(km) {
  if (!isNaN(km)) return getFixed(km, 2).replace(".", ",");
  return km.replace(".", ",");
}

export function isEmpty(value) {
  if(trim(value) === "") {
    return true;
  }
  return false;
}

export function trim(string) {
  if(string === null) {
    string = "";
  }
  if(typeof string === "undefined") {
    string = "";
  }
  if(typeof string !== "string") {
    string = ` ${string} `;
  }
  return string.replace(/^\s+|\s+$/gm, "");
}

export function ucFirstAll(string) {
  try {
    let arrStr = string.split(" ");
    let arrWords = [];
    arrStr.map(item => {
      return arrWords.push((item.charAt(0).toUpperCase() + item.slice(1)));
    });
    return arrWords.join(" ");
  } catch (e) {
    return string;
  }
}


export function currencyToNumber(currency, precision = 2) {
  let fmt = String(currency)
    .replace("R$", "")
    .replace("L", "")
    .replace(/(?<=\d)[.,](?!\d+$)/g, "")
    .replace(",", ".");
  const pts = fmt.split(".");
  if (pts.length > 1) {
    if (+pts[0] === 0) fmt = pts.join(".");
    else if (pts[1].length === (precision + 1)) fmt = pts.join("");
  }
  return Number(fmt);
};

export function numberToCurrency(value, locale = "pt-BR", currency = "BRL", precision = 2) {
  const intlNFOpts = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).resolvedOptions();
  const output = value.toLocaleString(locale, {
    ...intlNFOpts,
    style: "decimal",
    precision
  });
  return output;
};

export function prettierJson(value) {
  try {
    const json = JSON.parse(value);
    return JSON.stringify(json, undefined, 2);
  } catch (error) {
    return value;
  }
}
